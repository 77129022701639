.modal__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal__box {
  width: 80%;
  max-width: 500px;
  background-color: rgb(81, 81, 81);
  border-radius: 15px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close__btn {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px;
}

.modal__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  gap: 0.5rem;
  text-align: left;

  > * {
    text-align: left;
    font-family: inherit;
    margin: 0;
    padding: 0.2rem;
  }

  h3 {
    margin-right: 1.2rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 0.6rem;
    }
  }
}
