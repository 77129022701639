:root {
  color-scheme: dark;
}

body {
  background-color: rgb(32, 32, 32);
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 100;
  user-select: none; /* Modern browsers */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE/Edge */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main__page-container {
  position: relative;
}

.whatsapp-icon {
  border-radius: 50%;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 1000;
  cursor: pointer;
  opacity: 0;
  transform: translateX(100px);
}

.whatsapp-icon img {
  width: 38px;
  height: auto;
}

.fade-in {
  opacity: 1;
  transform: translateX(0);
  transition: all 1s ease-in-out;
  transition-delay: 2s;
}
